const { GAME_ACTION, GAME_EVENT, UNIT_TYPE } = require('../const/')
const UNITS = require('../units/')

const card = {
    create,
    validate,
    options,
    bot,
    rule,
    basepower: 25,
    ratio: 0.25,
    type: 'DOWNWIND',
    title: 'Downwind',
    description:
        "Increases the unit's movement until reaching the maximum range of the Archer.",
}

module.exports = card

function create({ emitter, board, player_id }) {
    const state = {}

    // PUBLIC
    // PUBLIC
    // PUBLIC
    function init() {
        emitter.emit(GAME_EVENT.MESSAGE_SET, {
            message: 'Select one unit',
        })
        emitter.on(GAME_EVENT.TOUCH_CLICK, click)
        update()
    }

    function update() {
        clear()
        const tiles = options({ board, player_id }).map(
            ({ unit_id }) => board.state.units[unit_id].tile_id
        )
        state.tiles = tiles
        emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_SHOW, {
            tiles: tiles.map((tile_id) => ({ tile_id })),
        })
        // emitter.emit(GAME_EVENT.CAMERA_POSITION_TILES, { tiles })
    }

    function destroy() {
        clear()
        emitter.emit(GAME_EVENT.MESSAGE_UNSET, {})
        emitter.remove(GAME_EVENT.TOUCH_CLICK, click)
    }

    // PRIVATE
    // PRIVATE
    // PRIVATE
    function clear() {
        if (state.tiles !== undefined) {
            emitter.emit(GAME_EVENT.TILES_HIGHLIGHT_HIDE, {
                tiles: state.tiles,
            })
        }
    }

    function click({ position }) {
        const tile_id = board.positionToTile(position)
        const params = { tile_id }
        const valid = validate({ board, player_id, params })
        if (valid !== undefined) {
            emitter.emit(GAME_EVENT.CARD_USING, params)
        }
    }

    return {
        state,
        init,
        update,
        destroy,
    }
}

function getMaxRange(unit) {
    return unit.movement + unit.range[1]
}

function getUnit(unit_type) {
    return UNITS[unit_type]({ players: null, tile_id: null })
}

function getUnitMaxRange(unit_type) {
    return getMaxRange(getUnit(unit_type))
}

function options({ board, player_id }) {
    const team_id = board.getTeamByPlayer({ player_id })
    const maxrange_archer = getUnitMaxRange(UNIT_TYPE.ARCHER)
    const units = board.getUnitsByTeam({ team_id })
    return units
        .filter((unit_id) => {
            return getMaxRange(board.state.units[unit_id]) < maxrange_archer
        })
        .map((unit_id) => ({ unit_id }))
}

function validate({ board, player_id, params }) {
    const valid = options({ board, player_id })
        .map(({ unit_id }) => board.state.units[unit_id].tile_id)
        .includes(params.tile_id)

    if (valid) {
        return { unit_id: board.getUnitByTile({ tile_id: params.tile_id }) }
    }
}

function bot({ board, action, actions }) {
    const { player_id } = action.params
    const { unit_id } = action.params
    const unit = board.state.units[unit_id]
    const maxrange_unit = getUnitMaxRange(unit.unit_type)
    const maxrange_archer = getUnitMaxRange(UNIT_TYPE.ARCHER)
    const range_diff = maxrange_archer - maxrange_unit

    if (
        range_diff > 0 &&
        actions.length === 0 &&
        action.type === GAME_ACTION.UNIT_WALK &&
        board.state.players[player_id].recruited === board.state.units_player
    ) {
        const { movement } = unit
        const enemies_without = board.getEnemiesInRangeMovement({ unit_id })

        if (enemies_without.length === 0) {
            unit.movement = movement + range_diff
            const enemies_with = board.getEnemiesInRangeMovement({ unit_id })
            unit.movement = movement
            if (enemies_with.length > 0) {
                return 1
            }
        }
    }

    return 0
}

function rule({ action, board, applyPatch, addReaction, dop }) {
    const { type, params, uid } = action
    const icon = `card_${card.type.toLowerCase()}`

    // USE
    if (type === GAME_ACTION.CARD_USE && params.card_type === card.type) {
        const { unit_id } = params.subparams
        const maxrange = getMaxRange(board.state.units[unit_id])
        const maxrange_archer = getUnitMaxRange(UNIT_TYPE.ARCHER)
        const movement =
            maxrange_archer - maxrange + board.state.units[unit_id].movement

        addReaction({
            type: GAME_ACTION.CARD_REACTION_ADD,
            params: {
                uid,
                card_type: card.type,
                subparams: params.subparams,
                reactions: [
                    {
                        type: GAME_ACTION.BASIC_PATCH,
                        params: {
                            units: { [unit_id]: { movement } },
                        },
                    },
                    {
                        type: GAME_ACTION.UNIT_ICON_ADD,
                        params: {
                            uid,
                            icon,
                            unit_id,
                        },
                    },
                ],
            },
        })
    }

    // WALK
    else if (type === GAME_ACTION.UNIT_WALK) {
        Object.keys(board.state.temp).forEach((uid) => {
            const temp = board.state.temp[uid]
            if (
                temp.card_type === card.type &&
                temp.subparams.unit_id === params.unit_id
            ) {
                const { unit_id } = params
                const { unit_type } = board.state.units[unit_id]
                const { movement } = getUnit(unit_type)
                addReaction({
                    type: GAME_ACTION.CARD_REACTION_REMOVE,
                    params: {
                        uid,
                        reactions: [
                            {
                                type: GAME_ACTION.BASIC_PATCH,
                                params: {
                                    units: {
                                        [unit_id]: { movement },
                                    },
                                },
                            },
                            {
                                type: GAME_ACTION.UNIT_ICON_REMOVE,
                                params: {
                                    uid,
                                    unit_id: params.unit_id,
                                },
                            },
                        ],
                    },
                })
            }
        })
    }
}
