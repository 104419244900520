const { GAME_ACTION } = require('../const')

function unitAttack({ action, board, applyPatch, addReaction }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_ATTACK) {
        const { unit_id, unit_id_target } = params
        action.patch = { units: { [unit_id]: { attacked: true } } }

        const { unpatch } = applyPatch(action.patch)
        action.unpatch = unpatch

        const unit = board.state.units[unit_id]
        const unit2 = board.state.units[unit_id_target]
        const damage = board.getDamage({
            unit_id1: unit_id,
            unit_id2: unit_id_target,
        })
        const life = unit2.life - damage
        const newlife = life < 0 ? 0 : life

        action.params.tile_id = unit.tile_id
        action.params.tile_id_target = unit2.tile_id
        action.params.damage = damage

        addReaction({
            type: GAME_ACTION.UNIT_HIT,
            params: {
                life: newlife,
                unit_id: unit_id_target,
                unit_id_origin: unit_id,
                damage,
            },
        })
    }
}

module.exports = unitAttack
