const {
    CARDS,
    REWARD_ASSET,
    REWARD_TYPE,
    CARD_RARITY,
    GAME_RATING_RESULT,
    GAME_ACTION,
    Xp,
} = require('../index')

function getRewardSearchQueue({ seconds }) {
    const coins_per_second = 0.1
    const max = 200
    const amount = Math.floor(seconds * coins_per_second)
    return {
        type: REWARD_TYPE.SEARCH_QUEUE,
        asset: REWARD_ASSET.COINS,
        amount: amount > max ? max : amount,
        seconds,
    }
}

function getRewardLevelUp({ xp_before, xp_after }) {
    const level_before = Xp.getLevel(xp_before)
    const level_after = Xp.getLevel(xp_after)
    return {
        type: REWARD_TYPE.LEVEL_UP,
        asset: REWARD_ASSET.TULIPS,
        amount: level_after.level > level_before.level ? 100 : 0,
    }
}

function getRewardFirstVictory({ now, last_win, result }) {
    const hours = 18 * 60 * 60 * 1000
    return {
        type: REWARD_TYPE.FIRST_VICTORY,
        asset: REWARD_ASSET.TULIPS,
        amount:
            result === GAME_RATING_RESULT.WIN && now() > last_win + hours
                ? 100
                : 0,
    }
}

function getRewardFirstGame({ now, last_game }) {
    const hours = 18 * 60 * 60 * 1000
    return {
        type: REWARD_TYPE.FIRST_GAME,
        asset: REWARD_ASSET.TULIPS,
        amount: now() > last_game + hours ? 100 : 0,
    }
}

function getRewardUnitsKilled({ history, player_id }) {
    const kills = history.filter((h) => {
        const attackfound = h.reactions.find(
            (r) => r.type === GAME_ACTION.UNIT_ATTACK
        )
        const deathfound = h.reactions.find(
            (r) => r.type === GAME_ACTION.UNIT_DEATH
        )
        return (
            attackfound &&
            deathfound &&
            attackfound.params.player_id === player_id &&
            attackfound.params.unit_id_target === deathfound.params.unit_id
        )
    })
    const amount = kills.length * 5
    return {
        type: REWARD_TYPE.UNITS_KILLED,
        asset: REWARD_ASSET.TULIPS,
        amount,
        kills: kills.length,
    }
}

function getRewardFlagsCaptured({ player_id, board }) {
    const team_id = board.getTeamByPlayer({ player_id })
    const flags = Object.keys(board.state.flags).filter((flag_id) => {
        return board.state.flags[flag_id].team_id === team_id
    })
    const amount = flags.length * 5
    return {
        type: REWARD_TYPE.FLAGS_CAPTURED,
        asset: REWARD_ASSET.TULIPS,
        amount,
        flags: flags.length,
    }
}

function getRewardBonusTime({ time }) {
    const amount = Math.round(time / 1000 / 20)
    return {
        type: REWARD_TYPE.BONUS_TIME,
        asset: REWARD_ASSET.TULIPS,
        amount,
    }
}

function getMilestoneRewards() {
    return [
        { value: 250, reward: { asset: REWARD_ASSET.ICON, id: 11 } },
        { value: 500, reward: { asset: REWARD_ASSET.TULIPS, amount: 500 } },
        {
            value: 750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.POISONEDARROW.type,
                rarity: CARD_RARITY.UNCOMMON,
            },
        },
        { value: 1000, reward: { asset: REWARD_ASSET.COINS, amount: 50 } },

        { value: 1250, reward: { asset: REWARD_ASSET.ICON, id: 12 } },
        { value: 1500, reward: { asset: REWARD_ASSET.TULIPS, amount: 1000 } },
        {
            value: 1750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.DOWNWIND.type,
                rarity: CARD_RARITY.UNCOMMON,
            },
        },
        { value: 2000, reward: { asset: REWARD_ASSET.COINS, amount: 100 } },

        { value: 2250, reward: { asset: REWARD_ASSET.ICON, id: 13 } },
        { value: 2500, reward: { asset: REWARD_ASSET.TULIPS, amount: 1500 } },
        {
            value: 2750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.HOLYBERRIES.type,
                rarity: CARD_RARITY.UNCOMMON,
            },
        },
        { value: 3000, reward: { asset: REWARD_ASSET.COINS, amount: 150 } },

        { value: 3250, reward: { asset: REWARD_ASSET.ICON, id: 14 } },
        { value: 3500, reward: { asset: REWARD_ASSET.TULIPS, amount: 2000 } },
        {
            value: 3750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.WOODSHIELD.type,
                rarity: CARD_RARITY.UNCOMMON,
            },
        },
        { value: 4000, reward: { asset: REWARD_ASSET.COINS, amount: 200 } },

        { value: 4250, reward: { asset: REWARD_ASSET.ICON, id: 15 } },
        { value: 4500, reward: { asset: REWARD_ASSET.TULIPS, amount: 2500 } },
        {
            value: 4750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.ADRENALINE.type,
                rarity: CARD_RARITY.UNCOMMON,
            },
        },
        { value: 5000, reward: { asset: REWARD_ASSET.COINS, amount: 250 } },

        { value: 5250, reward: { asset: REWARD_ASSET.ICON, id: 16 } },
        { value: 5500, reward: { asset: REWARD_ASSET.TULIPS, amount: 3000 } },
        {
            value: 5750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.POISONEDARROW.type,
                rarity: CARD_RARITY.RARE,
            },
        },
        { value: 6000, reward: { asset: REWARD_ASSET.COINS, amount: 300 } },

        { value: 6250, reward: { asset: REWARD_ASSET.ICON, id: 17 } },
        { value: 6500, reward: { asset: REWARD_ASSET.TULIPS, amount: 3500 } },
        {
            value: 6750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.DOWNWIND.type,
                rarity: CARD_RARITY.RARE,
            },
        },
        { value: 7000, reward: { asset: REWARD_ASSET.COINS, amount: 350 } },

        { value: 7250, reward: { asset: REWARD_ASSET.ICON, id: 18 } },
        { value: 7500, reward: { asset: REWARD_ASSET.TULIPS, amount: 4000 } },
        {
            value: 7750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.HOLYBERRIES.type,
                rarity: CARD_RARITY.RARE,
            },
        },
        { value: 8000, reward: { asset: REWARD_ASSET.COINS, amount: 400 } },

        { value: 8250, reward: { asset: REWARD_ASSET.ICON, id: 19 } },
        { value: 8500, reward: { asset: REWARD_ASSET.TULIPS, amount: 4500 } },
        {
            value: 8750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.WOODSHIELD.type,
                rarity: CARD_RARITY.RARE,
            },
        },
        { value: 9000, reward: { asset: REWARD_ASSET.COINS, amount: 450 } },

        { value: 9250, reward: { asset: REWARD_ASSET.ICON, id: 20 } },
        { value: 9500, reward: { asset: REWARD_ASSET.TULIPS, amount: 5000 } },
        {
            value: 9750,
            reward: {
                asset: REWARD_ASSET.CARD,
                card: CARDS.ADRENALINE.type,
                rarity: CARD_RARITY.RARE,
            },
        },
        { value: 10000, reward: { asset: REWARD_ASSET.COINS, amount: 500 } },
    ]
}

module.exports = {
    getRewardSearchQueue,
    getRewardLevelUp,
    getRewardFirstVictory,
    getRewardFirstGame,
    getRewardUnitsKilled,
    getRewardFlagsCaptured,
    getRewardBonusTime,
    getMilestoneRewards,
}
