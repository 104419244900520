const { GAME_ACTION } = require('../const')

function unitDeath({ action, addReaction, board, dop }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_DEATH) {
        const { unit_id } = params

        // Object.keys(board.state.temp).forEach((uid) => {
        //     const temp = board.state.temp[uid]
        //     if (temp.subparams.unit_id === unit_id) {
        //         addReaction({
        //             type: GAME_ACTION.CARD_REACTION_REMOVE,
        //             params: { uid },
        //         })
        //     }
        // })

        addReaction({
            type: GAME_ACTION.UNIT_REMOVE,
            params: { unit_id },
        })
    }
}

module.exports = unitDeath
