const { GAME_ACTION } = require('../const')

function unitHit({ action, board, applyPatch, addReaction }) {
    const { type, params } = action
    if (type === GAME_ACTION.UNIT_HIT) {
        addReaction({
            type: GAME_ACTION.UNIT_LIFE,
            params,
        })
    }
}

module.exports = unitHit
