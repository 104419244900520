const EVENT = {
    GAME_ACTION: 'GAME_ACTION',
    GAME_INTERACTION: 'GAME_INTERACTION',
}

const GAME_ACTION = {
    BASIC_PATCH: 'BASIC_PATCH',

    SET_BOARD: 'SET_BOARD',

    TURN_START: 'TURN_START',
    TURN_END: 'TURN_END',

    RECRUIT: 'RECRUIT',
    RECRUIT_AUTO: 'RECRUIT_AUTO',

    FLAG_CAPTURED: 'FLAG_CAPTURED',
    FLAG_ADD: 'FLAG_ADD',

    CARD_USE: 'CARD_USE',
    CARD_REACTION_ADD: 'CARD_REACTION_ADD',
    CARD_REACTION_REMOVE: 'CARD_REACTION_REMOVE',

    UNIT_ADD: 'UNIT_ADD',
    UNIT_REMOVE: 'UNIT_REMOVE',
    UNIT_CHANGE_TILE: 'UNIT_CHANGE_TILE',
    UNIT_WALK: 'UNIT_WALK',
    UNIT_LIFE: 'UNIT_LIFE',
    UNIT_DEATH: 'UNIT_DEATH',
    UNIT_HIT: 'UNIT_HIT',
    UNIT_ATTACK: 'UNIT_ATTACK',
    UNIT_ICON_ADD: 'UNIT_ICON_ADD',
    UNIT_ICON_REMOVE: 'UNIT_ICON_REMOVE',

    WINNER: 'WINNER',
}

const GAME_EVENT = {
    CANVAS_READY: 'CANVAS_READY',
    CANVAS_UNREADY: 'CANVAS_UNREADY',
    THREE_READY: 'THREE_READY',
    CAMERA_POSITION: 'CAMERA_POSITION',
    CAMERA_POSITION_CANCEL: 'CAMERA_POSITION_CANCEL',
    CAMERA_POSITION_TILES: 'CAMERA_POSITION_TILES',
    CARD_SELECT: 'CARD_SELECT',
    CARD_UNSELECT: 'CARD_UNSELECT',
    CARD_UNSELECT_ALL: 'CARD_UNSELECT_ALL',
    CARD_USING: 'CARD_USING',
    CARD_CANCEL: 'CARD_CANCEL',
    CARD_USED: 'CARD_USED',
    HAND_INTERVAL: 'HAND_INTERVAL',
    HAND_HIDE: 'HAND_HIDE',
    HAND_SHOW: 'HAND_SHOW',
    HIDE_TILES_OVER: 'HIDE_TILES_OVER',
    LOAD_HISTORY: 'LOAD_HISTORY',
    MESSAGE_SET: 'MESSAGE_SET',
    MESSAGE_UNSET: 'MESSAGE_UNSET',
    REACTION: 'REACTION',
    RECRUITING_START: 'RECRUITING_START',
    RECRUITING_CANCEL: 'RECRUITING_CANCEL',
    RECRUITING_CANCEL_ALL: 'RECRUITING_CANCEL_ALL',
    RECRUITING_DRAGGING: 'RECRUITING_DRAGGING',
    RECRUITING_MOVE: 'RECRUITING_MOVE',
    SEND_ACTION: 'SEND_ACTION',
    SEND_INTERACTION: 'SEND_INTERACTION',
    SET_STATE: 'SET_STATE',
    PATH_ATTACK_CREATE: 'PATH_ATTACK_CREATE',
    PATH_ATTACK_REMOVE: 'PATH_ATTACK_REMOVE',
    PATH_ATTACK_REMOVE_ALL: 'PATH_ATTACK_REMOVE_ALL',
    PATH_WALK_CREATE: 'PATH_WALK_CREATE',
    PATH_WALK_REMOVE: 'PATH_WALK_REMOVE',
    PATH_WALK_REMOVE_ALL: 'PATH_WALK_REMOVE_ALL',
    TILE_OVER: 'TILE_OVER',
    TILES_WALK_SHOW: 'TILES_WALK_SHOW',
    TILES_WALK_HIDE_ALL: 'TILES_WALK_HIDE_ALL',
    TILES_HIGHLIGHT_SHOW: 'TILES_HIGHLIGHT_SHOW',
    TILES_HIGHLIGHT_HIDE: 'TILES_HIGHLIGHT_HIDE',
    TILES_HIGHLIGHT_HIDE_ALL: 'TILES_HIGHLIGHT_HIDE_ALL',
    TILES_RANGE_CREATE: 'TILES_RANGE_CREATE',
    TILES_RANGE_VISIBLE: 'TILES_RANGE_VISIBLE',
    TILES_RANGE_MOVE: 'TILES_RANGE_MOVE',
    TILES_RANGE_REMOVE: 'TILES_RANGE_REMOVE',
    MOUSE_MOVE: 'MOUSE_MOVE',
    TOUCH_ACTION: 'TOUCH_ACTION',
    TOUCH_START: 'TOUCH_START',
    TOUCH_MOVE: 'TOUCH_MOVE',
    TOUCH_END: 'TOUCH_END',
    TOUCH_CLICK: 'TOUCH_CLICK',
    TOUCH_DRAGGED: 'TOUCH_DRAGGED',
    UNIT_SELECT: 'UNIT_SELECT',
    UNIT_UNSELECT: 'UNIT_UNSELECT',
    UNIT_UNSELECTED: 'UNIT_UNSELECTED',
    UNIT_WALK_ENDED: 'UNIT_WALK_ENDED',
    UNIT_ATTACK_ENDED: 'UNIT_ATTACK_ENDED',
    UNITS_HIGHLIGHT_SHOW: 'UNITS_HIGHLIGHT_SHOW',
    UPDATE_UNITS_ACTIONS: 'UPDATE_UNITS_ACTIONS',
    HIDE_ALL: 'HIDE_ALL',
    WINNED: 'WINNED',
}

const SERVER_METHOD = {
    GAME_RECRUIT: 'GAME_RECRUIT',
    GAME_UNIT_WALK: 'GAME_UNIT_WALK',
    GAME_UNIT_ATTACK: 'GAME_UNIT_ATTACK',
    GAME_CARD_USE: 'GAME_CARD_USE',
    GAME_TURN_END: 'GAME_TURN_END',
    GAME_RESIGN: 'GAME_RESIGN',
}

const USER_STATUS = {
    NONE: 'NONE',
    PARTY: 'PARTY',
    PLAYING: 'PLAYING',
}

const PARTY_STATUS = {
    NONE: 'NONE',
    SEARCHING: 'SEARCHING',
    MATCH_FOUND: 'MATCH_FOUND',
}

const GAME_TYPE = {
    // NORMAL: 'NORMAL',
    COMPETITIVE: 'COMPETITIVE',
    CUSTOM: 'CUSTOM',
}

const GAME_SIZE = {
    VS1: 1,
    // VS2: 2,
    // VS3: 3,
}

const GAME_RESULT = {
    VICTORY: 'VICTORY',
    DEFEAT: 'DEFEAT',
    DRAW: 'DRAW',
}

const WINNING_REASON = {
    ANNIHILATION: 'ANNIHILATION',
    FLAGS: 'FLAGS',
    RESIGN: 'RESIGN',
}

const TIMES = {
    MATCH_FOUND: 20000,
    STARTING: 10000,
    TURN: 90000,
    MARGIN_TURN_START: 1500,
    BONUS_PERCENT: 0.5,
    BONUS_START: 100 * 1000,
    BONUS_MAX: 200 * 1000,
}

const UNIT_TYPE = {
    KNIGHT: 'KNIGHT',
    SPEARMAN: 'SPEARMAN',
    AXEMAN: 'AXEMAN',
    ARCHER: 'ARCHER',
    SLINGER: 'SLINGER',
    CROSSBOWOMAN: 'CROSSBOWOMAN',
    // TOWER: 'TOWER',
}

const CARD_RARITY = {
    COMMON: 1,
    UNCOMMON: 2,
    RARE: 3,
    EPIC: 4,
    LEGENDARY: 5,
}

const CARD_RARITY_ID = {
    [CARD_RARITY.COMMON]: 'COMMON',
    [CARD_RARITY.UNCOMMON]: 'UNCOMMON',
    [CARD_RARITY.RARE]: 'RARE',
    [CARD_RARITY.EPIC]: 'EPIC',
    [CARD_RARITY.LEGENDARY]: 'LEGENDARY',
}

const CARD_RARITY_REDUCTION = {
    [CARD_RARITY.COMMON]: 0,
    [CARD_RARITY.UNCOMMON]: 0.25,
    [CARD_RARITY.RARE]: 0.5,
    [CARD_RARITY.EPIC]: 0.75,
    [CARD_RARITY.LEGENDARY]: 1,
}

const CARD_RARITY_COLORS = {
    [CARD_RARITY.COMMON]: ['#837263', '#9b8572'],
    [CARD_RARITY.UNCOMMON]: ['#536821', '#647c2c'],
    [CARD_RARITY.RARE]: ['#36a4cd', '#42aed6'],
    [CARD_RARITY.EPIC]: ['#7b07de', '#8f1eef'],
    [CARD_RARITY.LEGENDARY]: ['#f0770c', '#f8821c'],
}

const CARD_ACQUISITION = {
    REWARD: 1,
    SHOP: 2,
    GIVEAWAY: 3, // Event/tournament/promotion
}

const TEAM = {
    TEAM_1: 'team_1',
    TEAM_2: 'team_2',
}

const PLAYER = {
    PLAYER_1: 'player_1',
    PLAYER_2: 'player_2',
    PLAYER_3: 'player_3',
    PLAYER_4: 'player_4',
    PLAYER_5: 'player_5',
    PLAYER_6: 'player_6',
}

const PLAYERS_TEAM = {
    // Team 1
    [PLAYER.PLAYER_1]: TEAM.TEAM_1,
    [PLAYER.PLAYER_3]: TEAM.TEAM_1,
    [PLAYER.PLAYER_5]: TEAM.TEAM_1,
    // Team 2
    [PLAYER.PLAYER_2]: TEAM.TEAM_2,
    [PLAYER.PLAYER_4]: TEAM.TEAM_2,
    [PLAYER.PLAYER_6]: TEAM.TEAM_2,
}

const TEAM_PLAYERS = {
    [TEAM.TEAM_1]: Object.keys(PLAYERS_TEAM)
        .filter((pid) => PLAYERS_TEAM[pid] === TEAM.TEAM_1)
        .map((pid) => pid),
    [TEAM.TEAM_2]: Object.keys(PLAYERS_TEAM)
        .filter((pid) => PLAYERS_TEAM[pid] === TEAM.TEAM_2)
        .map((pid) => pid),
}

const COLOR_NAME = {
    BLUE: 'BLUE',
    GREEN: 'GREEN',
    PURPLE: 'PURPLE',
    RED: 'RED',
    ORANGE: 'ORANGE',
    YELLOW: 'YELLOW',
    WHITE: 'WHITE',
}

const COLOR_TEAM = {
    [TEAM.TEAM_1]: COLOR_NAME.BLUE,
    [TEAM.TEAM_2]: COLOR_NAME.RED,
}

const COLOR_PLAYER = {
    // Team 1
    [PLAYER.PLAYER_1]: COLOR_NAME.BLUE,
    [PLAYER.PLAYER_3]: COLOR_NAME.GREEN,
    [PLAYER.PLAYER_5]: COLOR_NAME.PURPLE,
    // Team 2
    [PLAYER.PLAYER_2]: COLOR_NAME.RED,
    [PLAYER.PLAYER_4]: COLOR_NAME.ORANGE,
    [PLAYER.PLAYER_6]: COLOR_NAME.YELLOW,
}

const COLOR_TEAMS_CODE = {
    BLUE: ['#4285ed', '#aaf0fe', '#609dfb'],
    RED: ['#ff3737', '#ffb0b0', '#ff5f5f'],
}

const COLOR_PLAYER_CODE = {
    [COLOR_NAME.BLUE]: '#4285ed',
    [COLOR_NAME.GREEN]: '#aaf0fe',
    [COLOR_NAME.PURPLE]: '#609dfb',
    //
    [COLOR_NAME.RED]: '#ff3737',
    [COLOR_NAME.ORANGE]: '#ffb0b0',
    [COLOR_NAME.YELLOW]: '#ff5f5f',
    //
    [COLOR_NAME.WHITE]: '#ffffff',
}

const VALIDATIONS = {
    LOGIN_CODE_LENGTH: 5,
    USERNAME_LENGTH: 20,
}

const REWARD_ASSET = {
    ICON: 1,
    CARD: 2,
    TULIPS: 3,
    COINS: 4,
}

const REWARD_TYPE = {
    SEARCH_QUEUE: 'SEARCH_QUEUE',
    LEVEL_UP: 'LEVEL_UP',
    FIRST_VICTORY: 'FIRST_VICTORY',
    FIRST_GAME: 'FIRST_GAME',
    UNITS_KILLED: 'UNITS_KILLED',
    FLAGS_CAPTURED: 'FLAGS_CAPTURED',
    BONUS_TIME: 'BONUS_TIME',
}

const GAME_RATING_RESULT = {
    WIN: 1,
    LOSE: 0,
    DRAW: 0.5,
}

const SHOP_CATEGORY = {
    DAILY: 'DAILY',
    CARDS: 'CARDS',
    TULIPS_COINS: 'TULIPS_COINS',
}

const SHOP_METHOD = {
    TULIPS: 0,
    COINS: 1,
    DOLLARS: 2,
}

const DEPOSIT_STATUS = {
    PENDING: 0, // pending to be processed
    PROCESSING: 2, // with this flag we avoid multiple processing for the same deposit (the process is async)
    COMPLETED: 1, // completed
}

module.exports = {
    EVENT,
    SERVER_METHOD,
    GAME_ACTION,
    GAME_EVENT,
    USER_STATUS,
    PARTY_STATUS,
    GAME_TYPE,
    GAME_SIZE,
    GAME_RESULT,
    TIMES,
    UNIT_TYPE,
    WINNING_REASON,
    CARD_RARITY,
    CARD_RARITY_ID,
    CARD_RARITY_REDUCTION,
    CARD_RARITY_COLORS,
    CARD_ACQUISITION,
    TEAM,
    PLAYER,
    PLAYERS_TEAM,
    TEAM_PLAYERS,
    COLOR_NAME,
    COLOR_TEAM,
    COLOR_PLAYER,
    COLOR_TEAMS_CODE,
    COLOR_PLAYER_CODE,
    VALIDATIONS,
    REWARD_ASSET,
    REWARD_TYPE,
    GAME_RATING_RESULT,
    SHOP_CATEGORY,
    SHOP_METHOD,
    DEPOSIT_STATUS,
}
