const findPath = require('../utils/findPath')
const {
    TIMES,
    TEAM,
    PLAYER,
    TEAM_PLAYERS,
    PLAYERS_TEAM,
    COLOR_NAME,
    COLOR_PLAYER,
} = require('../const')
const UNITS = require('../units')
const { minMax, groupRepeatedItems } = require('../utils')
const {
    getTileId,
    getCoordByTile,
    getDistanceFromPoints,
} = require('../utils/game')

function Board({
    state,
    created,
    game_id,
    type,
    size,
    dateNow = Date.now,
} = {}) {
    state = state || {
        created: created || dateNow(),
        game_id,
        type,
        size,
        name: null,
        title: null,
        cols: null,
        rows: null,
        units_player: null,
        flags_to_win: null,
        max_power: null,
        turn: 0,
        winner: null, // { team_id, reason: WINNING_REASON.ANNIHILATION }
        teams: {
            // [TEAM.TEAM_1]: {
            //     tiles: { from: '0.0', to: '24.2' },
            //     time: 159999 * 60 * 1000,
            // },
        },
        players: {
            // [PLAYER.PLAYER_1]: {
            //     team_id: TEAM.TEAM_1,
            //     cards: [],
            //     power: 100,
            //     recruited: 0,
            //     turn_started: null,
            //     turn_bonus: 0,
            //     subs: 0,
            //     abandon: 213131
            // },
        },
        flags: {
            // flag_1: { tile_id: '1.4', team_id: TEAM.TEAM_1 },
        },
        units: {
            // UNIQUE_ID: {
            //     attacked: false,
            //     walked: false,
            //     life: 3,
            //     maxlife: 3,
            //     movement: 4,
            //     players: ['player_1'],
            //     range: [1, 1],
            //     damage: 1,
            //     tile_id: '13.2',
            //     unit_type: 'KNIGHT',
            //     bonus: ['CROSSBOWOMAN', 'SLINGER'],
            //     icons: {},
            // },
        },
        tiles: {
            // '1.5': {
            //     habitable: false,
            // },
        },
        temp: {
            // [uid]: {
            //     card_type,
            //     step: 1,
            //     params: {}
            // }
        },
    }

    function now() {
        return dateNow() - state.created
    }

    function tileToPosition(tile_id) {
        const [col, row] = tile_id.split('.')
        return { col: Number(col), row: Number(row) }
    }

    function positionToTile({ col, row }) {
        return `${col}.${row}`
    }

    function isUnitPlayer({ unit_id, player_id }) {
        return state.units[unit_id].players.includes(player_id)
    }

    function isValidTile({ tile_id }) {
        const [x, y] = getCoordByTile(tile_id)
        return !(x < 0 || x > state.cols - 1 || y < 0 || y > state.rows - 1)
    }

    function isHabitableTile({ tile_id }) {
        if (
            !isValidTile({ tile_id }) ||
            (state.tiles.hasOwnProperty(tile_id) &&
                state.tiles[tile_id].habitable === false)
        ) {
            return false
        }

        return true
    }

    function isHabitableAndWalkableTile({ tile_id }) {
        if (!isHabitableTile({ tile_id })) {
            return false
        }
        for (const unit_id in state.units) {
            if (state.units[unit_id].tile_id === tile_id) {
                return false
            }
        }

        return true
    }

    function isValidTileToWalk({ unit_id, tile_id }) {
        const unit = state.units[unit_id]

        if (unit.walked || !isHabitableAndWalkableTile({ tile_id })) {
            return false
        }

        const path = getWalkablePath({
            tile_id_from: unit.tile_id,
            tile_id_to: tile_id,
        })
        return !(path.length === 0 || path.length > unit.movement)
    }

    function isValidTileToAttack({ unit_id, tile_id }) {
        const unit = state.units[unit_id]
        const unit_id_target = getUnitByTile({ tile_id })
        const team_id = getTeamByPlayer({ player_id: unit.players[0] })
        const enemies = getUnitsEnemies({ team_id })

        return (
            unit_id_target !== undefined &&
            enemies.includes(unit_id_target) &&
            isAttackableRange({
                tile_id1: unit.tile_id,
                tile_id2: tile_id,
                range: unit.range,
            })
        )
    }

    function isValidTileToAttackWithMovement({ unit_id, tile_id }) {
        const { attack } = getUnitActions({ unit_id })
        const unit_id_target = getUnitByTile({ tile_id })
        return attack.find(({ unit_id }) => unit_id === unit_id_target)
    }

    function isAttackableRange({ tile_id1, tile_id2, range }) {
        const distance = getDistanceFromTiles({ tile_id1, tile_id2 })
        const [min, max] = range
        return distance >= min && distance <= max
    }

    function isValidToRecruit({ player_id }) {
        const recruited = state.players[player_id].recruited
        const player_id_enemy = getOppositePlayer({ player_id })
        return (
            recruited < state.units_player &&
            recruited <= state.players[player_id_enemy].recruited
        )
    }

    function getTurnStatusByPlayer({ player_id }) {
        const { turn_started, turn_bonus } = state.players[player_id]
        const is_turn_team = !(turn_started === null)
        const turn_time = TIMES.TURN
        const diff = turn_time - (now() - turn_started)
        const available_timer = minMax(diff, 0, turn_time)
        const available_bonus = is_turn_team
            ? minMax(diff + turn_bonus, 0, turn_bonus)
            : turn_bonus
        const new_bonus = minMax(
            turn_bonus + (diff > 0 ? diff * TIMES.BONUS_PERCENT : diff),
            0,
            TIMES.BONUS_MAX
        )
        const is_time_left = is_turn_team
            ? available_timer + available_bonus > 0
            : false
        const can_action = is_turn_team && is_time_left

        return {
            is_turn_team,
            is_time_left,
            can_action,
            available_timer,
            available_bonus,
            new_bonus,
            turn_bonus,
            turn_started,
            turn_time,
        }
    }

    function getTeamPlaying() {
        let player_id
        for (player_id in state.players) {
            if (getTurnStatusByPlayer({ player_id }).is_turn_team) {
                break
            }
        }
        const { team_id } = state.players[player_id]
        return team_id
    }

    function getOppositePlayer({ player_id }) {
        const team_id = getTeamByPlayer({ player_id })
        const team_id_enemy =
            team_id === TEAM.TEAM_1 ? TEAM.TEAM_2 : TEAM.TEAM_1
        const players = getPlayersByTeam({ team_id })
        const players_enemy = getPlayersByTeam({ team_id: team_id_enemy })
        const index = players.indexOf(player_id)
        return players_enemy[index]
    }

    function getHabitableTiles({ tiles }) {
        const units_tiles = Object.keys(state.units).map(
            (unit_id) => state.units[unit_id].tile_id
        )
        return tiles.filter(
            (tile_id) =>
                isHabitableTile({ tile_id }) && !units_tiles.includes(tile_id)
        )
    }

    function getTilesByDistance({ tiles, tile_id }) {
        return tiles
            .map((tile_id2) => {
                const distance = getMathDistanceFromTiles({
                    tile_id1: tile_id,
                    tile_id2,
                })
                return { tile_id: tile_id2, distance }
            })
            .sort((a, b) => a.distance - b.distance)
            .map((tile) => tile.tile_id)
    }

    function getTilesFromSquare({ from, to }) {
        const [from_x, from_y] = getCoordByTile(from)
        const [to_x, to_y] = getCoordByTile(to)
        const tiles = []
        for (let x = from_x; x <= to_x; ++x) {
            for (let y = from_y; y <= to_y; ++y) {
                tiles.push(`${x}.${y}`)
            }
        }
        return tiles
    }

    function getTilesByRange({ tile_id, range }) {
        const [origin_x, origin_y] = getCoordByTile(tile_id)
        const tiles = []
        const [from, to] = range
        for (x = origin_x - to; x <= origin_x + to; ++x) {
            for (y = origin_y - to; y <= origin_y + to; ++y) {
                // if (!(x === origin_x && y === origin_y)) {
                const distance = getDistanceFromPoints({
                    x1: x,
                    y1: y,
                    x2: origin_x,
                    y2: origin_y,
                })
                if (distance <= to && distance >= from) {
                    tiles.push(getTileId(x, y))
                }
            }
        }
        return tiles //.filter((tile_id) => isValidTile({ tile_id }))
    }

    function getTilesCanRecruit({ player_id }) {
        const tile_id_center = getTileCenter()
        const tiles_team = getTilesFromSquare(
            state.teams[getTeamByPlayer({ player_id })].tiles
        )
        const tiles_habitable = getHabitableTiles({
            tiles: tiles_team,
        })
        return getTilesByDistance({
            tiles: tiles_habitable,
            tile_id: tile_id_center,
        })
    }

    function getTileCenter() {
        return positionToTile({
            col: (state.cols - 1) / 2,
            row: (state.rows - 1) / 2,
        })
    }

    function getTeamByUnit({ unit_id }) {
        const player_id = state.units[unit_id].players[0]
        return getTeamByPlayer({ player_id })
    }

    function getTeamByPlayer({ player_id }) {
        return PLAYERS_TEAM[player_id]
    }

    function getPlayersByTeam({ team_id }) {
        return TEAM_PLAYERS[team_id]
    }

    function getPlayerByUnit({ unit_id }) {
        if (state.units.hasOwnProperty(unit_id))
            return state.units[unit_id].players[0]
    }

    function getUnitByTile({ tile_id }) {
        const { units } = state
        for (let unit_id in units) {
            if (units[unit_id].tile_id === tile_id) {
                return unit_id
            }
        }
    }

    function getUnitsByPlayer({ player_id }) {
        return Object.keys(state.units).filter((unit_id) =>
            state.units[unit_id].players.includes(player_id)
        )
    }

    function getUnitsByTeam({ team_id }) {
        return Object.keys(state.players)
            .filter((player_id) => state.players[player_id].team_id === team_id)
            .reduce(
                (units, player_id) =>
                    units.concat(getUnitsByPlayer({ player_id })),
                []
            )
    }

    function getUnitsEnemies({ team_id }) {
        return Object.keys(state.players)
            .filter((player_id) => state.players[player_id].team_id !== team_id)
            .reduce(
                (units, player_id) =>
                    units.concat(getUnitsByPlayer({ player_id })),
                []
            )
    }

    function getUnitsEnemiesBonus({ team_id, unit_id }) {
        return getUnitsEnemies({
            team_id,
        }).filter(
            (unit_id_enemy) =>
                getDamage({
                    unit_id1: unit_id,
                    unit_id2: unit_id_enemy,
                }) > 1
        )
    }

    function getTileByUnit({ unit_id }) {
        return state.units[unit_id].tile_id
    }

    function getFlagByTile({ tile_id }) {
        for (let flag_id in state.flags) {
            if (state.flags[flag_id].tile_id === tile_id) {
                return flag_id
            }
        }
    }

    function getFlagsByTeam({ team_id }) {
        return Object.keys(state.flags).filter(
            (flag_id) => state.flags[flag_id].team_id === team_id
        )
    }

    function getUncaptureFlags({ team_id }) {
        return Object.keys(state.flags).filter(
            (flag_id) => state.flags[flag_id].team_id !== team_id
        )
    }

    function getUnitAvailability({ unit_id }) {
        const { walked, attacked } = state.units[unit_id]
        const attack = attacked
            ? []
            : walked
            ? getEnemiesInRange({ unit_id })
            : getEnemiesInRangeMovement({ unit_id })
        const can_walk = attacked === false && walked === false
        const can_attack = attacked === false && attack.length > 0

        return {
            unit_id,
            available: can_walk || can_attack,
            can_walk,
            can_attack,
        }
    }

    function getWalkablePath({ tile_id_from, tile_id_to }) {
        const [x1, y1] = getCoordByTile(tile_id_from)
        const [x2, y2] = getCoordByTile(tile_id_to)
        const origin = { x: x1, y: y1 }
        const destiny = { x: x2, y: y2 }
        return findPath(origin, destiny, (from, { x, y }) =>
            isHabitableAndWalkableTile({
                state,
                tile_id: getTileId(x, y),
            })
        )
            .map((node) => node.id)
            .slice(1)
    }

    function getWalkableTiles({ unit_id }) {
        const { tile_id, movement } = state.units[unit_id]
        return getTilesByRange({ tile_id, range: [1, movement] }).filter(
            (tile_id) => isValidTileToWalk({ unit_id, tile_id })
        )
    }

    function getUnitActions({ unit_id }) {
        const unit = state.units[unit_id]
        const { range, tile_id, walked } = unit
        const enemies = walked
            ? getEnemiesInRange({ unit_id })
            : getEnemiesInRangeMovement({ unit_id })

        const walk = walked
            ? [tile_id]
            : [tile_id].concat(getWalkableTiles({ unit_id }))

        const attack = enemies
            .map((enemy) => ({
                ...enemy,
                tiles: walk
                    .filter((tile_id) =>
                        isAttackableRange({
                            tile_id1: tile_id,
                            tile_id2: enemy.tile_id,
                            range,
                        })
                    )
                    .map((tile_id) => ({
                        tile_id,
                        distance: getMathDistanceFromTiles({
                            tile_id1: tile_id,
                            tile_id2: unit.tile_id,
                        }),
                    }))
                    .sort((a, b) => a.distance - b.distance),
            }))
            .filter((enemy) => enemy.tiles.length > 0)

        return {
            walk: walk.slice(1),
            attack,
        }
    }

    function getEnemies({ unit_id }) {
        const team_id = getTeamByUnit({ unit_id })
        return getUnitsEnemies({ team_id })
    }

    function getEnemiesInRangeRaw({ unit_id, range }) {
        const team_id = getTeamByUnit({ unit_id })
        const enemies = getUnitsEnemies({ team_id })
        const unit = state.units[unit_id]
        range = range || unit.range

        return enemies.filter((enemy_id) =>
            isAttackableRange({
                tile_id1: unit.tile_id,
                tile_id2: state.units[enemy_id].tile_id,
                range,
            })
        )
    }

    function getEnemiesInRange({ unit_id, range }) {
        return getEnemiesInRangeRaw({ unit_id, range })
            .map((enemy_id) => ({
                unit_id: enemy_id,
                tile_id: state.units[enemy_id].tile_id,
                life: state.units[enemy_id].life,
                bonus: getIfBonus({ unit_id1: unit_id, unit_id2: enemy_id }),
                damage: getDamage({ unit_id1: unit_id, unit_id2: enemy_id }),
                distance: getMathDistanceFromTiles({
                    tile_id1: state.units[unit_id].tile_id,
                    tile_id2: state.units[enemy_id].tile_id,
                }),
            }))
            .sort((a, b) => a.distance - b.distance)
            .sort((a, b) => a.life - a.damage - (b.life - b.damage))
    }

    function getEnemiesInRangeMovement({ unit_id }) {
        const unit = state.units[unit_id]
        return getEnemiesInRange({ unit_id, range: getRangeMovement(unit) })
    }

    function getDistanceFromTiles({ tile_id1, tile_id2 }) {
        const [x1, y1] = getCoordByTile(tile_id1)
        const [x2, y2] = getCoordByTile(tile_id2)
        return getDistanceFromPoints({ x1, y1, x2, y2 })
    }

    function getMathDistanceFromTiles({ tile_id1, tile_id2 }) {
        // https://stackoverflow.com/questions/20916953/get-distance-between-two-points-in-canvas
        const [x1, y1] = getCoordByTile(tile_id1)
        const [x2, y2] = getCoordByTile(tile_id2)
        const a = x1 - x2
        const b = y1 - y2
        return Math.sqrt(a * a + b * b)
    }

    function getRangeMovement({ range, movement }) {
        const rangemovement = [range[0] - movement || 1, range[1] + movement]
        if (rangemovement[0] < 1) {
            rangemovement[0] = 1
        }
        return rangemovement
    }

    function getRangeFromTile({ tile_id, range }) {
        const from = tileToPosition(tile_id)
        const to = tileToPosition(tile_id)
        from.col -= range
        from.row -= range
        to.col += range
        to.row += range
        return { from: positionToTile(from), to: positionToTile(to) }
    }

    function getDamage({ unit_id1, unit_id2 }) {
        const unit1 = state.units[unit_id1]
        return getIfBonus({ unit_id1, unit_id2 })
            ? unit1.damage + 1
            : unit1.damage
    }

    function getIfBonus({ unit_id1, unit_id2 }) {
        const unit1 = state.units[unit_id1]
        const unit2 = state.units[unit_id2]
        return unit1.bonus.includes(unit2.unit_type)
    }

    function getMiddlePosition({ tiles }) {
        tiles = tiles.map(tileToPosition)
        return {
            col: Math.round(
                tiles.reduce((val, p) => p.col + val, 0) / tiles.length
            ),
            row: Math.round(
                tiles.reduce((val, p) => p.row + val, 0) / tiles.length
            ),
        }
    }

    function getUnitAvailabilityByPlayer({ player_id }) {
        return getUnitsByPlayer({ player_id }).map((unit_id) =>
            getUnitAvailability({ unit_id })
        )
    }

    function getPlayerColors({ player_id }) {
        const colors = {}
        const team_id = getTeamByPlayer({ player_id })
        const invert = team_id === TEAM.TEAM_2
        Object.keys(PLAYERS_TEAM).forEach((player_id) => {
            colors[player_id] = invert
                ? COLOR_PLAYER[getOppositePlayer({ player_id })]
                : COLOR_PLAYER[player_id]
        })

        if (colors[player_id] !== COLOR_NAME.BLUE) {
            const player_id2 = !invert ? PLAYER.PLAYER_1 : PLAYER.PLAYER_2
            const color = colors[player_id]
            colors[player_id] = COLOR_NAME.BLUE
            colors[player_id2] = color
        }

        return colors
    }

    function getColorByPlayer({ player_id, me = PLAYER.PLAYER_1 }) {
        const team_player = getTeamByPlayer({ player_id })
        const team_me = getTeamByPlayer({ player_id: me })
        const colors = getPlayerColors({ player_id: me })

        return {
            team: team_player === team_me ? COLOR_NAME.BLUE : COLOR_NAME.RED,
            player: colors[player_id],
        }
    }

    function getCard({ player_id, card_id }) {
        return state.players[player_id].cards.find(
            (card) => card.id === card_id
        )
    }

    function getUnitCounters({ unit_id }) {
        const unit_types = []
        const { unit_type } = state.units[unit_id]
        Object.keys(UNITS).forEach((enemy_type) => {
            if (UNITS[enemy_type]({}).bonus.includes(unit_type)) {
                unit_types.push(enemy_type)
            }
        })
        return unit_types
    }

    function getTeamCounters({ team_id }) {
        const units = getUnitsEnemies({ team_id })
        const counters = units.map((unit_id) => getUnitCounters({ unit_id }))
        return groupRepeatedItems([].concat(...counters))
    }

    function getWalkableTilesForAttack({ unit_id, enemy_id }) {
        const unit = state.units[unit_id]
        const enemy = state.units[enemy_id]
        const tiles = [unit.tile_id]
            .concat(getWalkableTiles({ unit_id }))
            .filter((tile_id) =>
                isAttackableRange({
                    tile_id1: tile_id,
                    tile_id2: enemy.tile_id,
                    range: unit.range,
                })
            )
            .map((tile_id) => ({
                tile_id,
                distance: getMathDistanceFromTiles({
                    tile_id1: tile_id,
                    tile_id2: unit.tile_id,
                }),
            }))
            .sort((a, b) => a.distance - b.distance)

        return tiles
    }

    function getUncapturedFlags({ team_id }) {
        return Object.keys(state.flags).filter((flag_id) => {
            return state.flags[flag_id].team_id !== team_id
        })
    }

    return {
        state,
        now,
        tileToPosition,
        positionToTile,
        isUnitPlayer,
        isValidTile,
        isHabitableTile,
        isHabitableAndWalkableTile,
        isValidTileToWalk,
        isValidTileToAttack,
        isValidTileToAttackWithMovement,
        isAttackableRange,
        isValidToRecruit,
        getTeamPlaying,
        getTurnStatusByPlayer,
        getOppositePlayer,
        getHabitableTiles,
        getTilesByDistance,
        getTilesFromSquare,
        getTilesCanRecruit,
        getTilesByRange,
        getTileCenter,
        getTeamByPlayer,
        getTeamByUnit,
        getPlayersByTeam,
        getPlayerByUnit,
        getUnitsByPlayer,
        getUnitsByTeam,
        getUnitsEnemies,
        getUnitsEnemiesBonus,
        getUnitByTile,
        getTileByUnit,
        getFlagByTile,
        getFlagsByTeam,
        getUncaptureFlags,
        getUnitAvailability,
        getUnitAvailabilityByPlayer,
        getWalkablePath,
        getWalkableTiles,
        getUnitActions,
        getEnemies,
        getEnemiesInRangeRaw,
        getEnemiesInRange,
        getEnemiesInRangeMovement,
        getDistanceFromTiles,
        getMathDistanceFromTiles,
        getRangeMovement,
        getRangeFromTile,
        getIfBonus,
        getDamage,
        getMiddlePosition,
        getPlayerColors,
        getColorByPlayer,
        getCard,
        getTeamCounters,
        getUnitCounters,
        getWalkableTilesForAttack,
        getUncapturedFlags,
    }
}

module.exports = Board
