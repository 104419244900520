// Cards
const POISONEDARROW = require('./poisonedarrow')
const DOWNWIND = require('./downwind')
const WOODSHIELD = require('./woodshield')
const HOLYBERRIES = require('./holyberries')
const ADRENALINE = require('./adrenaline')
const PROJECTILEBOUNCE = require('./projectilebounce')
const POISONANTIDOTE = require('./poisonantidote')

const CARDS = {
    POISONEDARROW,
    DOWNWIND,
    WOODSHIELD,
    HOLYBERRIES,
    ADRENALINE,
    PROJECTILEBOUNCE,
    POISONANTIDOTE,
}

module.exports = CARDS
